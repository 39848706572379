import AppStore from '../common/buttons/appStore';
import LogoWithLink from './logoWithLink';
import PropTypes from 'prop-types';
import MainDownload from './template';
import React from 'react';

const Render = ({ userPlatform, onClickEvent, logoOnTop, showMotto }) => {
  const secondContainerStyle = {
    gridTemplateRows: '1fr',
  };

  // all users from the simple landing page should be redirected to this
  // specific custom product page
  const appleCPPURL =
    'https://apps.apple.com/us/app/splice-video-editor-maker/id409838725?ppid=511cc357-b143-456a-9569-fb68d168af53';

  const logo = (
    <div className="block">
      {userPlatform !== 'Android' ? (
        <LogoWithLink
          store="apple"
          onClick={() => {
            onClickEvent({ clickedSourceId: 'main-logo' });
          }}
          target="_blank"
          url={appleCPPURL}
        />
      ) : null}
      {userPlatform === 'Android' ? (
        <LogoWithLink
          store="google"
          onClick={() => {
            onClickEvent({ clickedSourceId: 'main-logo' });
          }}
          target="_blank"
        />
      ) : null}
    </div>
  );

  const downloadButton = (
    <div>
      {userPlatform === undefined || userPlatform === 'Apple' ? (
        <AppStore
          className="mx-4"
          onClick={() => {
            onClickEvent({ clickedSourceId: 'appstore-top' });
          }}
          target="_blank"
          url={appleCPPURL}
        />
      ) : null}
    </div>
  );

  const motto = (
    <div style={{ textAlign: 'center', margin: '40px 20px auto 20px' }}>
      <h1 className="text-main-title my-4 md:my-6">
        <span style={{ color: 'lightslategrey' }}>Create videos</span>
        <br />
        that blow minds.
      </h1>
      <p>
        The most powerful mobile video editor around. Download Splice and start
        creating stunning videos within minutes.
      </p>
    </div>
  );

  return (
    <>
      <div className="flex flex-col space-y-4 md:space-y-0 md:block mx-auto mt-16">
        {logoOnTop ? logo : downloadButton}
      </div>

      <div className="mt-6 grid justify-center" style={secondContainerStyle}>
        {logoOnTop ? downloadButton : logo}
      </div>

      <div className="mt-6 grid justify-center">{showMotto ? motto : null}</div>
    </>
  );
};

Render.propTypes = {
  userPlatform: PropTypes.string,
  onClickEvent: PropTypes.func,
  logoOnTop: PropTypes.bool,
  showMotto: PropTypes.bool,
};

const DownloadNowSimple = ({ isDark, logoOnTop, showMotto }) => {
  return (
    <MainDownload
      body={Render}
      isDark={isDark}
      logoOnTop={logoOnTop}
      showMotto={showMotto}
    />
  );
};

DownloadNowSimple.propTypes = {
  // true for the dark theme, false for light
  isDark: PropTypes.bool,
  // true if the logo should be placed on top, false if the button should appear first
  logoOnTop: PropTypes.bool,
  // true if the motto should be added, false otherwise
  showMotto: PropTypes.bool,
};

export default DownloadNowSimple;
