import React, { useRef } from 'react';
import useAdjustLink from '../../hooks/use-adjust-link';
import Logo from '../layout/header/logo';
import PropTypes from 'prop-types';

const LogoWithLink = ({
  className = '',
  campaign = 'default',
  store = 'apple',
  url = null,
  ...rest
}) => {
  const ctaRef = useRef();
  if (url === null) {
    url = useAdjustLink({ campaign, store }, ctaRef);
  }
  return (
    <a href={url} ref={ctaRef} className={className} {...rest}>
      <Logo />
    </a>
  );
};

LogoWithLink.propTypes = {
  className: PropTypes.string,
  campaign: PropTypes.string,
  store: PropTypes.oneOf(['apple', 'google']),
  url: PropTypes.string,
};

export default LogoWithLink;
